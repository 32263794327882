define("ember-cropster-common/utils/dom/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.scrollTo = scrollTo;
  function scrollTo(el, _ref) {
    let {
      top,
      left = 0,
      behavior = 'smooth'
    } = _ref;
    try {
      el.scrollTo({
        top,
        left,
        behavior
      });
    } catch (e) {
      // Fall back to this if the other format fails
      // E.g. Samsung Internet does not support the object notation
      el.scrollTo(left, top);
    }
  }
  var _default = scrollTo;
  _exports.default = _default;
});