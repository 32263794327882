define("ember-cropster-common/components/c-input-clearable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ipraga4Q",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"input-clearable \",[22,\"wrapperClass\"]]]],[10,\"data-test-input-clear-wrapper\",\"\"],[8],[0,\"\\n  \"],[5,\"c-input\",[[12,\"data-test-input-clear-input\",\"true\"],[13,1]],[[\"@value\",\"@update\",\"@class\",\"@id\",\"@placeholder\",\"@type\",\"@min\",\"@max\",\"@step\"],[[22,\"value\"],[22,\"onUpdate\"],[22,\"class\"],[22,\"id\"],[22,\"placeholder\"],[22,\"type\"],[22,\"min\"],[22,\"max\"],[22,\"step\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"input-clearable__button\"],[12,\"data-test-input-clear-button\",\"\"],[3,\"action\",[[23,0,[]],\"clear\"]],[8],[0,\"\\n      \"],[1,[28,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--light-grey\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-input-clearable/template.hbs"
    }
  });
  _exports.default = _default;
});