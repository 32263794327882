define("ember-cropster-common/components/c-input-clearable/component", ["exports", "ember-cropster-common/components/c-input-clearable/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    value: undefined,
    wrapperClass: '',
    class: 'full-width',
    id: undefined,
    onUpdate: undefined,
    init() {
      this._super(...arguments);
      let {
        id
      } = this;
      if (!id) {
        Ember.set(this, 'id', `${Ember.guidFor(this)}-input`);
      }
    },
    actions: {
      clear() {
        this.onUpdate('');
        let input = document.querySelector(`#${this.id}`);
        if (input) {
          input.focus();
        }
      }
    }
  });
  _exports.default = _default;
});