define("ember-cropster-common/services/ajax", ["exports", "fetch", "ember-cropster-common/utils/merge-deep", "ember-cropster-common/errors/ajax", "ember-fetch/utils/serialize-query-params"], function (_exports, _fetch, _mergeDeep, _ajax, _serializeQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contentTypeIsJSON = contentTypeIsJSON;
  _exports.contentTypeIsText = contentTypeIsText;
  _exports.default = void 0;
  _exports.parseResponseContent = parseResponseContent;
  /**
   * A service to make ajax requests to an API, using `fetch` under the hood.
   *
   * Usage:
   *
   * ```js
   * ajax.request('/get-url');
   * ajax.post('/post-url', { data: myData });
   * ajax.patch('/patch-url', { data: myData });
   * ```
   *
   * @namespace Service
   * @class Ajax
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    namespace: null,
    host: null,
    headers: null,
    fetchOptions: null,
    contentType: 'application/json; charset=utf-8',
    /**
     * Make an Ajax request, via `fetch`.
     * This returns a promise, that will resolve depending on the returned Content-Type:
     *
     * * It will resolve with a JSON object, if Content-Type includes `json`
     * * It will resolve with a string, if Content-Type includes `text`
     * * Else, it will resolve with the fetch response object, which can be manually worked with (e.g. for binary data)
     *
     * In the case of an error, it will either return an `AjaxError`, or an `AjaxNetworkError`.
     * `AjaxNetworkError` is returned when the fetch operation fails, e.g. because of CORS issues or if the client is offline.
     * `AjaxError` is used when the server response is not OK.
     * It will always contain an `errors` arrays, similar to Ember Data errors, which can be used.
     *
     * The following options are allowed:
     *
     * * method (GET, POST, PUT, PATCH, DELETE)
     * * contentType (e.g. `application/json; charset=utf-8`)
     * * headers (an object of additional headers)
     * * data (will be used for the body, or as query params for GET requests)
     * * host (to overwrite the default host)
     * * namespace (to overwrite the default namespace)
     *
     * @method request
     * @param {String} url
     * @param {Object} options
     * @param {Object} customFetchOptions
     * @return {Promise<*>}
     */
    async request(url) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let customFetchOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      (false && !(url && typeof url === 'string') && Ember.assert('url must be specified for ajax.request()', url && typeof url === 'string'));
      (false && !(typeof options === 'object') && Ember.assert('options must be an object', typeof options === 'object'));
      (false && !(typeof customFetchOptions === 'object') && Ember.assert('customFetchOptions must be an object', typeof customFetchOptions === 'object'));
      (false && !(!Object.keys(options).find(optionName => !['method', 'contentType', 'headers', 'data', 'host', 'namespace'].includes(optionName))) && Ember.assert('Only the following options are allowed for ajax requests: `method`, `contentType`, `headers`, `data`, `host`, `namespace`. Use the third argument to directly pass options to `fetch`', !Object.keys(options).find(optionName => !['method', 'contentType', 'headers', 'data', 'host', 'namespace'].includes(optionName))));
      let fetchOptions = this._prepareFetchOptions(url, options, customFetchOptions);
      let fullUrl = this._buildUrl(url, options, customFetchOptions);
      let response;
      try {
        response = await (0, _fetch.default)(fullUrl, fetchOptions);
      } catch (error) {
        // If the error is a TypeError, it means the network request failed (e.g. offline, or CORS issues, ...)
        // We want to special-case this
        // See https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Checking_that_the_fetch_was_successful
        if (error instanceof TypeError) {
          let detail = `Network request failed for "${fullUrl}"`;
          let abortError = new _ajax.AjaxNetworkError(null, {
            detail
          });
          return this._handleError(abortError);
        }
        return this._handleError(error);
      }
      if (!response.ok) {
        return this._handleError(response);
      }
      return this._handleSuccessResponse(response);
    },
    /**
     * This is a shorthand for `request` with `method='POST'`.
     *
     * @method post
     * @param {String} url
     * @param {Object} options
     * @param {Object} customFetchOptions
     * @return {Promise<*>}
     */
    async post(url) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let customFetchOptions = arguments.length > 2 ? arguments[2] : undefined;
      options.method = 'POST';
      return this.request(url, options, customFetchOptions);
    },
    /**
     * This is a shorthand for `request` with `method='PATCH'`.
     *
     * @method patch
     * @param {String} url
     * @param {Object} options
     * @param {Object} customFetchOptions
     * @return {Promise<*>}
     */
    async patch(url) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let customFetchOptions = arguments.length > 2 ? arguments[2] : undefined;
      options.method = 'PATCH';
      return this.request(url, options, customFetchOptions);
    },
    /**
     * This is a shorthand for `request` with `method='PUT'`.
     *
     * @method put
     * @param {String} url
     * @param {Object} options
     * @param {Object} customFetchOptions
     * @return {Promise<*>}
     */
    async put(url) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let customFetchOptions = arguments.length > 2 ? arguments[2] : undefined;
      options.method = 'PUT';
      return this.request(url, options, customFetchOptions);
    },
    /**
     * This is a shorthand for `request` with `method='DELETE'`.
     *
     * @method delete
     * @param {String} url
     * @param {Object} options
     * @param {Object} customFetchOptions
     * @return {Promise<*>}
     */
    async delete(url) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let customFetchOptions = arguments.length > 2 ? arguments[2] : undefined;
      options.method = 'DELETE';
      return this.request(url, options, customFetchOptions);
    },
    /**
     * This is called when an error occurs, and is responsible for generating the correct error response.
     * This can either receive a fetch `Response` as an argument, or an Error.
     * If it is a `Response`, we build a nice `AjaxError` object from it.
     * Else, we just re-throw the error.
     *
     * @method _handleError
     * @param {Response|Error} responseOrError
     * @private
     */
    async _handleError(responseOrError) {
      if (responseOrError instanceof _fetch.Response) {
        let responseContent = await parseResponseContent(responseOrError);
        let errorData = this._getErrorDescription(responseOrError, responseContent);
        throw new _ajax.AjaxError(responseContent, errorData);
      }
      throw responseOrError;
    },
    /**
     * Handle the success response, and return the correct content.
     * This will either resolves with a JSON object, or with a string.
     *
     * Or, for other content types, it will just resolve with the `Response` object itself, for custom functionality.
     *
     * @method _handleSuccessResponse
     * @param {Response} response
     * @return {Promise<*>}
     * @private
     */
    async _handleSuccessResponse(response) {
      let responseContent;
      try {
        responseContent = await parseResponseContent(response);
      } catch (error) {
        return this._handleError(error);
      }
      return responseContent;
    },
    /**
     * Get a nice error description from a response.
     * This will return something like this:
     * `{ status: 400, code: 'bad_request', message: 'Bad Request', details: null }`
     *
     * Which can be used for the `AjaxError` object, to have an Ember Data-like error response.
     * It handles the common error cases: 400, 401, 403, 404, 409, 422, 500, 503
     *
     * Other errors will just use their fetch statusText as message.
     *
     * @method _getErrorDescription
     * @param {Response} response
     * @param {Object|String} responseContent
     * @return {Object}
     * @private
     */
    _getErrorDescription(response, responseContent) {
      let {
        status,
        statusText
      } = response;
      // If the error response has an errors object with `detail` in it, we use it
      let detail = responseContent ? Ember.get(responseContent, 'errors.firstObject.detail') : null;
      switch (status) {
        case 400:
          return {
            status,
            detail,
            code: 'bad_request',
            message: statusText
          };
        case 401:
          return {
            status,
            detail,
            code: 'unauthorized',
            message: 'You are not logged in'
          };
        case 403:
          return {
            status,
            detail,
            code: 'access_denied',
            message: 'You are not allowed to access this resource'
          };
        case 404:
          return {
            status,
            detail,
            code: 'resource_not_found',
            message: 'Resource was not found'
          };
        case 409:
          {
            return {
              status,
              detail,
              code: 'resource_exists',
              message: 'The resource already exists'
            };
          }
        case 422:
          return {
            status,
            detail,
            code: 'invalid_data_attribute',
            message: 'The data sent to the server was invalid'
          };
        case 500:
          return {
            status,
            detail,
            code: 'server_error',
            message: 'An internal server error occurred'
          };
        case 503:
          return {
            status,
            detail,
            code: 'maintenance',
            message: 'The server is currently undergoing maintenance work'
          };
      }
      return {
        status,
        message: statusText,
        detail
      };
    },
    /**
     * Prepare the options to be passed to fetch.
     *
     * @method _prepareFetchOptions
     * @param {String} url
     * @param {Object} options
     * @param {Object} customFetchOptions
     * @return {Object} The options for fetch
     * @private
     */
    _prepareFetchOptions(url, options, customFetchOptions) {
      let defaultFetchOptions = this.fetchOptions || {};
      let method = (options.method || 'GET').toUpperCase();
      let body = this._getFetchBody(options, customFetchOptions);
      let contentType = this._getFetchContentType(options, body);
      let headers = this._getFetchHeaders(options, contentType);
      let fetchOptions = (0, _mergeDeep.default)(defaultFetchOptions, customFetchOptions, {
        method,
        headers
      });

      // If body is FormData, it breaks with the mergeDeep implementation
      // So we just add it directly here instead
      if (!Ember.isNone(body)) {
        fetchOptions.body = body;
      }
      return fetchOptions;
    },
    _getFetchBody(options, customFetchOptions) {
      let {
        data
      } = options;
      let {
        body
      } = customFetchOptions;
      let method = (options.method || 'GET').toUpperCase();

      // Re-write `data` to `body`, if set - also converting JSON to a string
      let isMethodWithBody = ['POST', 'PATCH', 'PUT', 'DELETE'].includes(method);
      if (Ember.isNone(body) && isMethodWithBody) {
        body = data && typeof data === 'object' && !(data instanceof FormData) ? JSON.stringify(data) : data;
      }
      return body;
    },
    _getFetchContentType(options, body) {
      let contentType = options.hasOwnProperty('contentType') ? options.contentType : this.contentType;

      // Special case: if data is FormData, and no contentType is set, we default to null instead
      // This is needed by the browser to automatically generate the correct header with a boundary
      // If you'd specify `multipart/form-data` manually, it would lack the necessary boundary information
      // So the only way to make this work is by not specifying a content type header at all, and letting the browser handle it
      if (body instanceof FormData && !options.hasOwnProperty('contentType')) {
        contentType = null;
      }
      return contentType;
    },
    _getFetchHeaders(options, contentType) {
      let defaultHeaders = this.headers || {};
      let customHeaders = options.headers || {};
      let headers = (0, _mergeDeep.default)(defaultHeaders, {
        'Content-Type': contentType
      }, customHeaders);

      // Ensure you can set no content type
      if (!contentType) {
        delete headers['Content-Type'];
        delete headers['content-type'];
      }
      return headers;
    },
    /**
     * Build a given URL, taking the host & namespace into account.
     * By default, it will use the specified default `host` and `namespace`, and prepend it to the URL.
     * These properties can also be overwritten in the options.
     *
     * @method _buildUrl
     * @param {String} url
     * @param {Object} options
     * @param {Object} customFetchOptions
     * @return {String}
     * @private
     */
    _buildUrl(url) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // If an absolute URL is specified, just use it, no matter the other settings
      if (url.indexOf('http') === 0) {
        return url;
      }
      let {
        host,
        namespace
      } = this;
      if (options.hasOwnProperty('host')) {
        host = options.host;
      }
      if (options.hasOwnProperty('namespace')) {
        namespace = options.namespace;
      }

      // For GET requests, convert `data` to query params
      let method = (options.method || 'GET').toUpperCase();
      if (method === 'GET' && options.data) {
        let queryParams = (0, _serializeQueryParams.default)(options.data);
        if (url.indexOf('?') > -1) {
          url = `${url}&${queryParams}`;
        } else {
          url = `${url}?${queryParams}`;
        }
      }
      let urlParts = [host, namespace, url].filter(val => val);
      let fullUrl = urlParts.join('/');

      // Remove double slashes - but make sure http(s):// remains
      let regex = /(\w|^|-|_)(\/+)/g;
      return fullUrl.replace(regex, '$1/');
    }
  });
  _exports.default = _default;
  function contentTypeIsJSON(contentType) {
    return contentType.indexOf('json') > -1;
  }
  function contentTypeIsText(contentType) {
    return contentType.indexOf('text') > -1;
  }
  async function parseResponseContent(response) {
    // 204 means no content, so we just return the response object here - no matter what the content-type says
    if (response.status === 204) {
      return response;
    }
    let responseContentType = response.headers.get('Content-Type') || '';
    if (contentTypeIsJSON(responseContentType)) {
      try {
        return await response.json();
      } catch (error) {
        throw new Error('Could not parse JSON response');
      }
    }
    if (contentTypeIsText(responseContentType)) {
      return await response.text();
    }

    // Fall back to just returning the response object, for custom stuff
    return response;
  }
});