define("ember-cropster-common/utils/eq-array-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = eqArrayContent;
  function eqArrayContent(arr1, arr2) {
    // If one of them isn't an array, return false
    if (Ember.typeOf(arr1) !== 'array' || Ember.typeOf(arr2) !== 'array') {
      return false;
    }

    // Equal array instances always match
    if (arr1 === arr2) {
      return true;
    }
    let {
      length
    } = arr1;
    // If the array length doesn't match, return false
    if (length !== arr2.length) {
      return false;
    }

    // Check every array element
    for (let i = 0; i < length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }
});