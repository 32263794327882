define("ember-cropster-common/helpers/eq-array-content", ["exports", "ember-cropster-common/utils/eq-array-content"], function (_exports, _eqArrayContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.eqArrayContent = eqArrayContent;
  function eqArrayContent(_ref) {
    let [arr1, arr2] = _ref;
    return (0, _eqArrayContent.default)(arr1, arr2);
  }

  /**
   * A helper to compare to arrays for equal content.
   *
   * @class EqArrayContent
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{eq-array-content (to-array 1 2) (to-array 1 2)}}
   * ```
   */
  var _default = Ember.Helper.helper(eqArrayContent);
  _exports.default = _default;
});