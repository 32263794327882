define("ember-cropster-common/helpers/optional-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.optionalAction = optionalAction;
  /**
   * A helper to mark a closure action as optional.
   *
   * @class OptionalAction
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{some-component
   *   event=(action (optional-action @optionalAction) someArg)}}
   * ```
   */
  function optionalAction(_ref) {
    let [action] = _ref;
    if (Ember.typeOf(action) === 'function') {
      return action;
    }
    return function () {};
  }
  var _default = Ember.Helper.helper(optionalAction);
  _exports.default = _default;
});