define("ember-cropster-common/components/c-card/component", ["exports", "ember-cropster-common/components/c-card/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['padding-all-3', 'bg-color-white', 'border-all-radius-1', 'box-shadow'],
    classNameBindings: ['withBorder:border-all', 'withBorder:border-all-color-grey-2'],
    /*
     * Wheter the border should be included.
     * @param bool
     * @default false
     */
    withBorder: false
  });
  _exports.default = _default;
});