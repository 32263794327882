define("ember-cropster-common/components/c-button/component", ["exports", "ember-cropster-common/components/c-button/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TYPE_TO_CLASS = {
    primary: 'button--primary',
    secondary: 'button--secondary',
    light: 'button--light',
    plain: 'button--plain',
    'plain-primary': 'button--plain--primary',
    error: 'button--error',
    warning: 'button--warning',
    saved: 'button--saved'
  };
  function isValidButtonType(type) {
    if (Ember.isNone(type)) {
      // Currently, we can't make type required as the button may be
      // used still with the 'button--[type]' classes applied.
      // Once we migrate successfully to type attribute, then we can
      // make it mandatory or provide sensible default value.
      return true;
    }
    return !Ember.isNone(TYPE_TO_CLASS[type]);
  }
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'button',
    attributeBindings: ['name', 'disabled', 'height', 'name', 'type', 'width', 'tooltip:data-tooltip', 'data-test'],
    classNames: ['button'],
    classNameBindings: ['isLoading:button--loading', 'autoLoading:button--loading', 'tooltip:button--tooltip', 'isSmall:button--small', 'iconName:button--icon', 'iconRight:button--icon--right', 'variantClass'],
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * If the button should be disabled.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,
    /**
     * Button variant.
     *
     * @attribute type
     * @type String
     * @default null
     * @public
     */
    type: null,
    /**
     * If this is set, show a tooltip on hover.
     *
     * @attribute tooltip
     * @type {String}
     * @public
     */
    tooltip: null,
    /**
     * If true, a loading spinner is shown and the button cannot be clicked.
     *
     * @attribute isLoading
     * @type {Boolean}
     * @default false
     * @public
     */
    isLoading: false,
    /**
     * Make the button in a small variant.
     *
     * @attribute isSmall
     * @type {Boolean}
     * @default false
     * @public
     */
    isSmall: false,
    /**
     * Icon name
     *
     * @attribute iconName
     * @type String
     * @default null
     * @public
     */
    iconName: null,
    /**
     * Moves icon to right.
     *
     * @attribute iconRight
     * @type Bool
     * @default false
     * @public
     */
    iconRight: false,
    /**
     * This action is called when the button is clicked and it is neither disabled not loading.
     *
     * @event onClick
     * @public
     */
    onClick: null,
    action: null,
    _onClick: Ember.computed('onClick', 'action', function () {
      return this.onClick || this.action;
    }),
    autoLoading: Ember.computed.alias('sendActionTask.isRunning'),
    variantClass: Ember.computed('type', function () {
      return TYPE_TO_CLASS[this.type];
    }),
    click() {
      if (!Ember.get(this, 'disabled') && !Ember.get(this, 'isLoading') && !Ember.get(this, 'autoLoading')) {
        Ember.get(this, 'sendActionTask').perform();
        return true;
      }
      return false;
    },
    didReceiveAttrs() {
      this._super(...arguments);
      (false && !(isValidButtonType(this.type)) && Ember.assert(`[c-button:type]: '${this.type}' is not a valid button type`, isValidButtonType(this.type)));
    },
    sendActionTask: (0, _emberConcurrency.task)(function* () {
      if (this._onClick) {
        return yield this._onClick();
      }
    })
  });
  _exports.default = _default;
});