define("ember-cropster-common/initializers/iphone-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // Adds iphone-browser css class to body so we can fix
  // the input zoom issue https://cropster.atlassian.net/browse/CCF-956
  function initialize() {
    let isIphone = navigator.userAgent.includes('iPhone');
    if (isIphone) {
      let body = document.querySelector('body');
      body.classList.add('iphone-browser');
    }
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});