define("ember-cropster-common/helpers/map-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mapBy = mapBy;
  function mapBy(_ref) {
    let [arr, propertyName] = _ref;
    (false && !(typeof propertyName === 'string') && Ember.assert('propertyName is set', typeof propertyName === 'string'));
    if (!arr) {
      return null;
    }
    return arr.mapBy(propertyName);
  }

  /**
   * A helper to map an array by a given field.
   *
   * @class MapBy
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{map-by (to-array (hash name='John')) 'name'}}
   * ```
   */
  var _default = Ember.Helper.helper(mapBy);
  _exports.default = _default;
});