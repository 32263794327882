define("ember-l10n/utils/get-locale-asset-map", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocaleAssetMap = getLocaleAssetMap;
  function getLocaleAssetMap() {
    // FastBoot cannot read from document, so we require a (specifically built) file in that scenario
    if (typeof FastBoot !== 'undefined') {
      let assetMap = (0, _require.default)("ember-l10n/fastboot-locale-asset-map");
      return assetMap.default;
    }
    let metaTag = document.querySelector("meta[name='ember-l10n:localeAssetMap']");
    if (!metaTag || !metaTag.content) {
      // eslint-disable-next-line no-console
      (false && !(false) && Ember.assert('<meta name="ember-l10n:localeAssetMap"> tag is missing.', false));
      return {};
    }
    let assetMapString = metaTag.content;
    return JSON.parse(decodeURIComponent(assetMapString));
  }
});