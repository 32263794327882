define("ember-cropster-common/components/bread-crumbs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zGZEIvK6",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[7,\"li\",true],[8],[0,\"\\n\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"breadcrumbs__item\",[24,[\"rootPage\"]]]],{\"statements\":[[0,\"    Home\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,1,[\"route\"]],[23,1,[\"model\"]]],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"breadcrumbs__item\",[23,1,[\"route\"]],[23,1,[\"model\"]]]],{\"statements\":[[4,\"if\",[[23,1,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Loading...\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"route\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"breadcrumbs__item\",[23,1,[\"route\"]]]],{\"statements\":[[4,\"if\",[[23,1,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Loading...\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"breadcrumbs__item breadcrumbs__item--no-link\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Loading...\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/bread-crumbs/template.hbs"
    }
  });
  _exports.default = _default;
});