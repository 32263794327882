define("ember-cropster-common/helpers/is-odd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isOdd = isOdd;
  function isOdd(params) {
    if (!params) {
      return false;
    }
    let [number] = params;
    if (Ember.typeOf(number) !== 'number') {
      return false;
    }
    return Math.abs(number) % 2 === 1;
  }
  var _default = Ember.Helper.helper(isOdd);
  _exports.default = _default;
});