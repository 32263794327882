define("ember-cropster-common/errors/ajax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AjaxNetworkError = _exports.AjaxError = void 0;
  class AjaxError extends Ember.Error {
    constructor(payload) {
      let {
        status,
        message = 'Ajax operation failed',
        detail,
        code = 'error'
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      super(message);
      this.code = code;
      this.payload = payload;
      this.status = status;
      this.errors = [{
        code,
        detail: detail || message,
        status: `${status}`,
        title: message
      }];
    }
  }
  _exports.AjaxError = AjaxError;
  class AjaxNetworkError extends Ember.Error {
    constructor(payload) {
      let {
        status = 0,
        message = 'Network request failed',
        detail,
        code = 'offline'
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      super(message);
      this.code = code;
      this.payload = payload;
      this.status = status;
      this.errors = [{
        code,
        detail: detail || message,
        status: `${status}`,
        title: message
      }];
    }
  }
  _exports.AjaxNetworkError = AjaxNetworkError;
});