define("ember-cropster-common/utils/serialize-object", ["exports", "moment", "ember-cropster-common/utils/merge-deep"], function (_exports, _moment, _mergeDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.deserializeDateOrDateString = deserializeDateOrDateString;
  _exports.deserializeObject = deserializeObject;
  _exports.deserializeOrCopyJSON = deserializeOrCopyJSON;
  _exports.isDateOrDateString = isDateOrDateString;
  _exports.isDateString = isDateString;
  _exports.serializeDateOrDateString = serializeDateOrDateString;
  _exports.serializeObject = serializeObject;
  /**
   * This util exports two main functions: `serializeObject` and `deserializeObject`.
   *
   * @namespace Utils
   * @class SerializeObject
   * @public
   */
  var _default = {
    serializeObject,
    deserializeObject
  };
  /**
   * This util function serializes an object to a string.
   * If the given value is already a string, it will leave it as it is.
   * Additionally, it will specially serialize arrays of moment-instances (=date ranges) into the YYYY-MM-DD format.
   *
   * @method serializeObject
   * @param {Object|String} value
   * @return {String}
   * @public
   */
  _exports.default = _default;
  function serializeObject(value) {
    if (!value) {
      return null;
    }
    let json = deserializeOrCopyJSON(value);

    // Correctly serialize date ranges
    Object.keys(json).forEach(i => {
      let val = json[i];
      if (Ember.typeOf(val) === 'array' && Ember.get(val, 'length') === 2) {
        let [dateFrom, dateTo] = val;
        if (isDateOrDateString(dateFrom) || isDateOrDateString(dateTo)) {
          json[i] = [serializeDateOrDateString(dateFrom), serializeDateOrDateString(dateTo)];
        }
      }
    });
    return JSON.stringify(json);
  }

  /**
   * This util function deserializes a string to an object.
   * If the given value is already an object, it will leave it as it is.
   * Additionally, it will specially deserialize arrays of moment-instances (=date ranges) from the YYYY-MM-DD format.
   *
   * @method deserializeObject
   * @param {Object|String} value
   * @return {Object}
   * @public
   */
  function deserializeObject(value) {
    if (!value) {
      return {};
    }
    let json = deserializeOrCopyJSON(value);

    // Correctly serialize date ranges
    Object.keys(json).forEach(i => {
      let val = json[i];
      if (Ember.typeOf(val) === 'array' && Ember.get(val, 'length') === 2) {
        let [dateFrom, dateTo] = val;
        if (isDateOrDateString(dateFrom) || isDateOrDateString(dateTo)) {
          json[i] = [deserializeDateOrDateString(dateFrom), deserializeDateOrDateString(dateTo)];
        }
      }
    });
    return json;
  }
  function deserializeDateOrDateString(value) {
    if (!value) {
      return null;
    }
    if (_moment.default.isMoment(value)) {
      return value;
    }
    if (isDateString(value)) {
      return (0, _moment.default)(value, 'YYYY-MM-DD').startOf('day');
    }
    return value;
  }
  function serializeDateOrDateString(value) {
    if (!value) {
      return null;
    }
    if (_moment.default.isMoment(value)) {
      return value.format('YYYY-MM-DD');
    }
    if (isDateString(value)) {
      return value;
    }
    return value;
  }
  function deserializeOrCopyJSON(value) {
    return Ember.typeOf(value) === 'string' ? JSON.parse(value) : (0, _mergeDeep.default)(value);
  }
  function isDateString(str) {
    let date = (0, _moment.default)(str, 'YYYY-MM-DD');
    return date.isValid() && date.format('YYYY-MM-DD') === str;
  }
  function isDateOrDateString(value) {
    return _moment.default.isMoment(value) || isDateString(value);
  }
});