define("ember-cropster-common/helpers/format-nl2br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.escapeHTML = escapeHTML;
  _exports.formatNl2br = formatNl2br;
  function escapeHTML(str) {
    if (!document) {
      return str;
    }
    return document.createElement('div').appendChild(document.createTextNode(str)).parentNode.innerHTML;
  }
  function formatNl2br(_ref) {
    let [str] = _ref;
    let {
      escape = true
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let breakTag = '<br>';
    if (Ember.String.isHTMLSafe(str)) {
      str = str.toString();
    }
    if (Ember.typeOf(str) !== 'string') {
      return '';
    }

    // First, escape all tags in there
    if (escape) {
      str = escapeHTML(str);
    }
    return Ember.String.htmlSafe(str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`));
  }

  /**
   * A helper to add line breaks to a text.
   * Note that this will escape other characters in the string!
   *
   * @class FormatNl2br
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{format-nl2br "1
   * 2
   * 3"}}
   * ```
   */
  var _default = Ember.Helper.helper(formatNl2br);
  _exports.default = _default;
});