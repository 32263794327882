define("ember-service-worker-update-notify/utils/service-worker-has-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serviceWorkerHasUpdate;
  const {
    resolve
  } = Ember.RSVP;
  function serviceWorkerHasUpdate() {
    return resolve('hasServiceWorkerUpdate' in window ? window.hasServiceWorkerUpdate : false);
  }
});