define("ember-cropster-common/components/dropdown-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zPYVkULg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",true],[11,\"class\",[29,[\"button button--dropdown \",[28,\"if\",[[24,[\"buttonClass\"]],[24,[\"buttonClass\"]],\"button--light\"],null],[28,\"if\",[[24,[\"buttonIcon\"]],\" button--icon\"],null],\"\\n    \",[28,\"if\",[[24,[\"isOpen\"]],\"button--dropdown--open\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggle\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"buttonIcon\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"svg-icon\",[[24,[\"buttonIcon\"]]],[[\"class\"],[[24,[\"buttonIconClass\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[22,\"buttonText\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"dropdown-menu/dropdown-menu-content\",null,[[\"isOpen\",\"class\",\"alignLeft\",\"autoClose\",\"closeAction\"],[[24,[\"isOpen\"]],[24,[\"dropdownClass\"]],[24,[\"alignLeft\"]],[24,[\"autoClose\"]],[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/dropdown-menu/template.hbs"
    }
  });
  _exports.default = _default;
});