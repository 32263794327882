define("ember-cropster-common/utils/promise-queue", ["exports", "ember-cropster-common/utils/promise"], function (_exports, _promise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = promiseQueue;
  function promiseQueue(items, func) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      let queuedItems = items.toArray();
      let values = [];
      let loadNext = () => {
        let [nextItem] = queuedItems.splice(0, 1);
        if (nextItem) {
          (0, _promise.resolveIfPromise)(func(nextItem)).then(response => {
            values.push(response);
            loadNext();
          }, reject);
        } else {
          resolve(values);
        }
      };
      loadNext();
    });
  }
});