define("ember-cropster-common/components/info-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EzenaPyC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-test-info-message-icon\",\"true\"],[10,\"class\",\"margin-top-3 margin-left-3 margin-bottom-3 self-center\"],[8],[0,\"\\n    \"],[1,[28,\"svg-icon\",[[24,[\"icon\"]]],[[\"class\"],[[28,\"concat\",[[24,[\"iconClass\"]],\" display-block\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex flex-column flex-grow-1 margin-all-3\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"data-test-info-message-title\",\"true\"],[10,\"class\",\"margin-bottom-2 bold\"],[8],[0,\"\\n      \"],[1,[22,\"title\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"data-test-info-message-content\",\"true\"],[10,\"class\",\"line-height-3\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"onClose\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"self-start\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"data-test-info-message-close-btn\",\"true\"],[10,\"class\",\"info-message__close\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]],[8],[0,\"\\n      \"],[1,[28,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--small\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/info-message/template.hbs"
    }
  });
  _exports.default = _default;
});