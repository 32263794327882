define("ember-cropster-common/helpers/window-size-lt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.windowSizeLt = windowSizeLt;
  function windowSizeLt(_ref) {
    let [width, gt = false] = _ref;
    if (!window) {
      return false;
    }
    let screenWidth = Ember.get(window, 'innerWidth');
    if (!screenWidth) {
      return false;
    }
    return gt ? screenWidth > width : screenWidth < width;
  }

  /**
   * A helper to check if the current window size is less than the given value.
   * Also takes an optional second parameter "true"
   * to instead check if the screen size is greater than (instead of less then).
   *
   * @class WindowSizeLt
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{window-size-lt 1024}}
   * ```
   * @example
   * ```handlebars
   * {{window-size-lt 1024 true}}
   * ```
   */
  var _default = Ember.Helper.helper(windowSizeLt);
  _exports.default = _default;
});