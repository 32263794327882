define("ember-cropster-common/utils/eq-object-content", ["exports", "ember-cropster-common/utils/eq-array-content"], function (_exports, _eqArrayContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = eqObjectContent;
  /*
   * Makes a deep comparison of enumerable object properties.
   *
   * @param  {Object} objectA
   * @param  {Object} objectB
   * @param  {Function} [comparatorFunction=(a,b) => a===b]
   * @return {Boolean}
   */
  function eqObjectContent(objectA, objectB, comparatorFunction) {
    comparatorFunction = Ember.typeOf(comparatorFunction) === 'function' ? comparatorFunction : (a, b) => a === b;

    // first: all keys must have same length
    let keysA = Object.keys(objectA).sort();
    let keysB = Object.keys(objectB).sort();
    if (keysA.length !== keysB.length) {
      return false;
    }

    // second: all keys should be the same
    let sameKeys = keysA.every((keyA, idx) => keyA === keysB[idx]);
    if (sameKeys === false) {
      return false;
    }

    // check if any value is not equal
    return keysA.every(key => {
      let valueA = objectA[key];
      let valueB = objectB[key];
      switch (Ember.typeOf(valueA)) {
        case 'array':
          return (0, _eqArrayContent.default)(valueA, valueB, comparatorFunction);
        case 'object':
          return eqObjectContent(valueA, valueB, comparatorFunction);
        case 'instance':
          return eqObjectContent(valueA, valueB, (a, b) => Ember.get(a, 'id') === Ember.get(b, 'id'));
        default:
          return comparatorFunction(valueA, valueB);
      }
    });
  }
});