define("ember-cropster-common/mixins/scroll-to-id", ["exports", "ember-cropster-common/utils/dom/element-offset", "ember-cropster-common/utils/dom/scroll-to"], function (_exports, _elementOffset, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * On transition, scroll to element with ID of hash, if set.
   * e.g. /route#my-element will try to scroll to `<div id='my-element'></div>`.
   *
   * @namespace EmberCropsterCommon.Mixin
   * @class ScrollToId
   * @extends Ember.Mixin
   * @public
   */
  var _default = Ember.Mixin.create({
    setupController() {
      this._super(...arguments);
      return new Ember.RSVP.Promise(resolve => {
        Ember.run.later(() => {
          let {
            hash
          } = window.location;

          // Allow to override - this is for tests only
          if (window.locationHashOverride) {
            hash = window.locationHashOverride;
          }
          if (!hash) {
            resolve();
            return;
          }

          /* eslint-disable ember-suave/no-direct-property-access */
          let body = Ember.testing ? document.querySelector('#ember-testing-container') : window;
          let el = document.querySelector(hash);
          if (el) {
            let offset = 50;
            let scrollTop = Ember.testing ? el.offsetTop / 2 : (0, _elementOffset.default)(el).top;
            (0, _scrollTo.scrollTo)(body, {
              top: scrollTop - offset,
              left: 0,
              behavior: 'smooth'
            });
            Ember.run.later(resolve, 500);
          } else {
            resolve();
          }
        }, 100);
      });
    }
  });
  _exports.default = _default;
});