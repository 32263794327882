define("ember-cropster-common/helpers/to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toString = toString;
  function toString(_ref) {
    let [val] = _ref;
    if (!val && val !== '') {
      return null;
    }
    return `${val}`;
  }

  /**
   * A helper to convert a given value to a string.
   *
   * @class ToString
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{to-string 1}}
   * ```
   */
  var _default = Ember.Helper.helper(toString);
  _exports.default = _default;
});