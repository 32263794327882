define("ember-cropster-common/components/toast-container/message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4pJLSGhk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"svg-icon\",[[24,[\"type\"]]],[[\"class\"],[\"toast-icon icon--inherit-color\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"toast-wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"toast-title\"],[10,\"data-test-toast-title\",\"\"],[8],[0,\"\\n      \"],[1,[22,\"title\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"message\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"toast-message\"],[10,\"data-test-toast-message\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"format-nl2br\",[[24,[\"message\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"button\",true],[10,\"class\",\"toast-close-button\"],[10,\"data-toast-close\",\"\"],[8],[0,\"\\n  \"],[1,[28,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--small icon--inherit-color\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"toast-progress-bar\"],[10,\"data-test-toast-progress-bar\",\"\"],[11,\"style\",[22,\"progressBarStyle\"]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/toast-container/message/template.hbs"
    }
  });
  _exports.default = _default;
});