define("ember-cropster-common/components/c-box/component", ["exports", "ember-cropster-common/components/c-box/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['border-all', 'border-all-radius-1', 'transition-common', 'overflow-hidden'],
    classNameBindings: ['isTypeBasic:bg-color-white', 'isTypeBasic:border-all-color-grey-3', 'isTypeHighlight:bg-color-grey-4', 'isTypeHighlight:border-all-color-grey-4', 'isTypeHighlight:border-all-hover-color-grey-3', 'isTypePrimary:bg-color-white', 'isTypePrimary:border-all-color-primary', 'isTypePrimary:border-all-hover-color-grey-3', 'isTypeWhite:bg-color-white', 'isTypeWhite:border-all-color-grey-3'],
    /*
     * Either 'highlight', 'primary', 'white' or empty for default type.
     *
     * @type {String}
     * @public
     */
    type: null,
    isTypeBasic: Ember.computed.empty('type'),
    isTypeHighlight: Ember.computed.equal('type', 'highlight'),
    isTypePrimary: Ember.computed.equal('type', 'primary'),
    isTypeWhite: Ember.computed.equal('type', 'white'),
    didReceiveAttrs() {
      this._super(...arguments);
      (false && !(!this.type || ['highlight', 'primary', 'white'].includes(this.type)) && Ember.assert(`You set type="${this.type}" on c-box, but only highlight, primary & white are allowed.`, !this.type || ['highlight', 'primary', 'white'].includes(this.type)));
    }
  });
  _exports.default = _default;
});