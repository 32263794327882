define("ember-cropster-common/helpers/get-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This helper makes a simple lookup within given
   * object on first level only (unless `dotted` is
   * false). This allows also dots in hash keys, as
   * this is not possible with ember's get() helper.
   *
   * @class GetValue
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{get-value (hash prop1=1) 'prop1' dotted=true}}
   * ```
   */
  var _default = Ember.Helper.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Makes hash map lookup on first level.
     *
     * @method compute
     * @param {Array} params Positional params
     * @param {Object} [hash={}]
     * @public
     *
     * @return {String}
     */
    compute(_ref, hash) {
      let [obj, key] = _ref;
      if (!Ember.isPresent(obj) || !Ember.isPresent(key)) {
        return undefined;
      }
      switch (Ember.typeOf(obj)) {
        case 'object':
        case 'instance':
          break;
        default:
          return undefined;
      }
      if (hash.dotted) {
        return Ember.get(obj, key);
      }
      if (!obj.hasOwnProperty(key)) {
        return undefined;
      }
      return obj[key];
    }
  });
  _exports.default = _default;
});