define("ember-cropster-common/utils/dom/event-listener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventListener = addEventListener;
  _exports.removeEventListener = removeEventListener;
  const _cache = {};
  function addEventListener(el, event, id, func) {
    let key = `${event}.${id}`;

    // If an event listener already exists, remove it
    removeEventListener(el, event, id);
    el.addEventListener(event, func);
    _cache[key] = func;
  }
  function removeEventListener(el, event, id) {
    let key = `${event}.${id}`;
    let func = _cache[key];
    if (func) {
      el.removeEventListener(event, func);
      _cache[key] = undefined;
    }
  }
});