define("ember-cropster-common/components/c-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8wLeIgxg",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"span\",true],[11,\"class\",[22,\"wrapperClass\"]],[8],[0,\"\\n  \"],[7,\"label\",true],[11,\"for\",[22,\"id\"]],[11,\"class\",[28,\"class-names\",[\"toggle__label\",[24,[\"labelClass\"]]],null]],[8],[1,[22,\"labelOff\"],false],[9],[0,\"\\n\\n  \"],[5,\"c-input\",[[12,\"data-test-checkbox\",\"true\"],[13,1]],[[\"@type\",\"@class\",\"@id\",\"@checked\",\"@onUpdate\"],[\"checkbox\",[28,\"class-names\",[\"toggle\",[24,[\"class\"]],[28,\"if\",[[28,\"not\",[[24,[\"isOnOffChoice\"]]],null],\"toggle--grey\"],null]],null],[22,\"id\"],[22,\"checked\"],[28,\"action\",[[23,0,[]],\"onUpdate\"],null]]]],[0,\"\\n  \"],[7,\"label\",true],[11,\"for\",[22,\"id\"]],[8],[9],[0,\"\\n\\n  \"],[7,\"label\",true],[11,\"for\",[22,\"id\"]],[11,\"class\",[28,\"class-names\",[\"toggle__label\",[24,[\"labelClass\"]]],null]],[8],[1,[22,\"labelOn\"],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-toggle/template.hbs"
    }
  });
  _exports.default = _default;
});