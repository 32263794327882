define("ember-cropster-common/services/bread-crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This service is responsible for holding bread crumb information.
   *
   * @namespace Service
   * @class BreadCrumbs
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    /**
     * The current bread crumb hierarchy.
     *
     * @property hierarchy
     * @type {Array}
     * @protected
     */
    hierarchy: null,
    /**
     * Add a property to the hierarchy.
     *
     * @method add
     * @param {Object} route The route information to add
     * @public
     */
    add(route) {
      let hierarchy = Ember.get(this, 'hierarchy');
      hierarchy.addObject(route);
      return hierarchy;
    },
    /**
     * Add a property at a certain position in the hierarchy.
     *
     * @method addAt
     * @param {Object} route The route information to add
     * @param {Number} pos The position to insert the property at
     * @public
     */
    addAt(route, pos) {
      let hierarchy = Ember.get(this, 'hierarchy');
      hierarchy.insertAt(pos, route);
      return hierarchy;
    },
    /**
     * Add a property at a certain offset from the last item.
     * E.g. `this.addFromBehind(obj, 1)` will insert it as second-to-last item.
     *
     * @method addFromBehind
     * @param {Object} route The route information to add
     * @param {Number} pos The position to insert the property at
     * @public
     */
    addFromBehind(route) {
      let pos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      let hierarchy = Ember.get(this, 'hierarchy');
      hierarchy.insertAt(Ember.get(hierarchy, 'length') - pos, route);
      return hierarchy;
    },
    /**
     * Remove a property from the hierarchy.
     *
     * @method remove
     * @param {Object} route The route information to remove
     * @public
     */
    remove(route) {
      let hierarchy = Ember.get(this, 'hierarchy');
      hierarchy.removeObject(route);
      return hierarchy;
    },
    /**
     * Clear the hierarchy information.
     *
     * @method clear
     * @public
     */
    clear() {
      let hierarchy = [];
      Ember.set(this, 'hierarchy', hierarchy);
      return hierarchy;
    },
    init() {
      this._super(...arguments);
      Ember.set(this, 'hierarchy', []);
    }
  });
  _exports.default = _default;
});