define("ember-l10n/templates/get-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V2eu09r9",
    "block": "{\"symbols\":[\"part\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"messageParts\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"name\"]]],null,{\"statements\":[[14,2,[[23,1,[\"text\"]],[23,1,[\"name\"]]]]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"unescapeText\"]]],null,{\"statements\":[[1,[23,1,[\"text\"]],true]],\"parameters\":[]},{\"statements\":[[1,[23,1,[\"text\"]],false]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-l10n/templates/get-text.hbs"
    }
  });
  _exports.default = _default;
});