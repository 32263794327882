define("ember-cropster-common/components/c-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ei2KV28b",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[5,\"c-input\",[[12,\"data-test-checkbox\",\"true\"],[13,1]],[[\"@type\",\"@class\",\"@id\",\"@checked\",\"@onUpdate\"],[\"checkbox\",[22,\"class\"],[22,\"id\"],[22,\"checked\"],[28,\"action\",[[23,0,[]],\"onUpdate\"],null]]]],[0,\"\\n\"],[7,\"label\",true],[11,\"for\",[22,\"id\"]],[11,\"class\",[22,\"labelClass\"]],[8],[1,[22,\"label\"],false],[14,2],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-checkbox/template.hbs"
    }
  });
  _exports.default = _default;
});