define("ember-cropster-common/utils/set-nested-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setNestedProperty;
  function setNestedProperty(obj, property, value) {
    if (Ember.typeOf(obj) !== 'object' && Ember.typeOf(obj) !== 'instance') {
      throw new Error('The first argument for setNestedProperty must to be an object.');
    }
    if (Ember.typeOf(property) !== 'string' || property.length === 0) {
      throw new Error('The second argument for setNestedProperty must to be a string.');
    }

    // If the property is not nested, just set it
    let propertyPath = property.split('.');
    if (Ember.get(propertyPath, 'length') === 1) {
      return Ember.set(obj, property, value);
    }

    // If the property exists, simply overwrite it
    let exitingProperty = Ember.get(obj, property);
    if (exitingProperty) {
      return Ember.set(obj, property, value);
    }

    // Else, created the nested structure
    let currentObj = obj;
    let [lastProperty] = propertyPath.splice(-1);
    propertyPath.forEach(path => {
      // Check if the nested object already exists
      let newObj = Ember.get(currentObj, path);
      if (Ember.typeOf(newObj) !== 'object') {
        newObj = {};
        currentObj[path] = newObj;
      }
      currentObj = newObj;
    });
    currentObj[lastProperty] = value;
    return value;
  }
});