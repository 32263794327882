define("ember-cropster-common/helpers/is-not-none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isNotNone = isNotNone;
  function isNotNone(_ref) {
    let [val] = _ref;
    return !Ember.isNone(val);
  }

  /**
   * A helper to check if a given value is not null or undefined.
   *
   * @class IsNotNone
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{is-not-none null}}
   * ```
   */
  var _default = Ember.Helper.helper(isNotNone);
  _exports.default = _default;
});