define("ember-cropster-common/helpers/get-array-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getArrayElement = getArrayElement;
  function getArrayElement(_ref) {
    let [arr, i] = _ref;
    if (Ember.typeOf(arr) !== 'array' || Ember.typeOf(i) === 'undefined') {
      return null;
    }
    return arr[i];
  }

  /**
   * Get the element at the given position from an array.
   *
   * @class GetArrayElement
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{get-array-element (to-array 'a' 'b') 1}}
   * ```
   */
  var _default = Ember.Helper.helper(getArrayElement);
  _exports.default = _default;
});