define("ember-cropster-common/components/c-radio-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A7/5nLhV",
    "block": "{\"symbols\":[\"opt\",\"i\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"c-input\",null,[[\"type\",\"name\",\"checked\",\"id\",\"value\",\"onUpdate\",\"data-test-radio-button\"],[\"radio\",[24,[\"elementId\"]],[28,\"eq\",[[23,1,[]],[24,[\"value\"]]],null],[28,\"concat\",[[24,[\"elementId\"]],\"-\",[23,2,[]]],null],[23,2,[]],[28,\"action\",[[23,0,[]],\"onUpdate\",[23,1,[]]],null],[23,2,[]]]]],false],[0,\"\\n\\n  \"],[7,\"label\",true],[11,\"for\",[29,[[22,\"elementId\"],\"-\",[23,2,[]]]]],[11,\"class\",[22,\"labelClass\"]],[8],[14,3,[[23,1,[]],[28,\"hash\",null,[[\"index\"],[[23,2,[]]]]]]],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-radio-buttons/template.hbs"
    }
  });
  _exports.default = _default;
});