define("ember-cropster-common/helpers/to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toArray = toArray;
  function toArray(items) {
    let [val] = items;
    if (Ember.typeOf(val) === 'array') {
      return val;
    }
    return items.toArray();
  }

  /**
   * A helper to convert one or multiple values into an array.
   *
   * If the given value is already an array, it will returned as is.
   * Give it as many positional arguments as you want to build an array!
   *
   * @class ToArray
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{to-array 1 2}}
   * ```
   */
  var _default = Ember.Helper.helper(toArray);
  _exports.default = _default;
});