define("ember-cropster-common/components/c-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mq1CTbB6",
    "block": "{\"symbols\":[\"@label\",\"@isSmall\",\"@iconName\",\"&default\"],\"statements\":[[4,\"if\",[[25,4]],null,{\"statements\":[[0,\"  \"],[14,4],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"svg-icon\",[[23,3,[]]],[[\"class\"],[[28,\"class-names\",[\"icon--inherit-color\",[28,\"if\",[[23,2,[]],\"icon--small\"],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-button/template.hbs"
    }
  });
  _exports.default = _default;
});