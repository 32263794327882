define("ember-cropster-common/components/c-toggle/component", ["exports", "ember-cropster-common/components/c-checkbox/component", "ember-cropster-common/components/c-toggle/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _template.default,
    wrapperClass: 'display-inline-block',
    labelOff: undefined,
    labelOn: undefined,
    isOnOffChoice: true
  });
  _exports.default = _default;
});