define("ember-cropster-common/components/c-button-saved/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lsDg5m0v",
    "block": "{\"symbols\":[\"@isSaved\",\"@saveLabel\",\"@savedLabel\",\"@disabled\",\"@tooltip\",\"@onClick\",\"&attrs\"],\"statements\":[[5,\"c-button\",[[13,7]],[[\"@type\",\"@label\",\"@iconName\",\"@disabled\",\"@tooltip\",\"@onClick\"],[[28,\"if\",[[23,1,[]],\"saved\",\"primary\"],null],[28,\"if\",[[23,1,[]],[23,3,[]],[23,2,[]]],null],[28,\"if\",[[23,1,[]],\"success\"],null],[23,4,[]],[23,5,[]],[23,6,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-button-saved/template.hbs"
    }
  });
  _exports.default = _default;
});