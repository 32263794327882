define("ember-l10n/test-helpers", ["exports", "ember-l10n/services/l10n"], function (_exports, _l10n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(context) {
    let tHelper = Ember.Helper.helper((_ref, hash) => {
      let [str] = _ref;
      return (0, _l10n.strfmt)(str, Ember.assign({}, hash));
    });
    let ptHelper = Ember.Helper.helper((_ref2, hash) => {
      let [str /* , ctxt*/] = _ref2;
      return (0, _l10n.strfmt)(str, Ember.assign({}, hash));
    });
    let nHelper = Ember.Helper.helper((_ref3, hash) => {
      let [strSingular, strPlural, count] = _ref3;
      return (0, _l10n.strfmt)(count !== 1 ? strPlural : strSingular, Ember.assign({
        count
      }, hash));
    });
    let pnHelper = Ember.Helper.helper((_ref4, hash) => {
      let [strSingular, strPlural, count /* , ctxt*/] = _ref4;
      return (0, _l10n.strfmt)(count !== 1 ? strPlural : strSingular, Ember.assign({
        count
      }, hash));
    });
    context.register('helper:t', tHelper);
    context.register('helper:n', nHelper);
    context.register('helper:pt', ptHelper);
    context.register('helper:pn', pnHelper);
    (false && !(false) && Ember.deprecate(`ember-l10n/test-helpers has been deprecated.
    You can use the helpers normally in your integration tests, without any further action.`, false, {
      id: 'ember-l10n.test-helpers',
      until: '4.0.0'
    }));
  }
});