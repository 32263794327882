define("ember-cropster-common/components/info-message/component", ["exports", "ember-cropster-common/components/info-message/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _template.default,
    'data-test-info-message': true,
    classNames: ['flex', 'border-all-radius-1'],
    classNameBindings: ['isTypeInfo:bg-color-info-light', 'isTypeError:bg-color-error-light', 'isTypeWarning:bg-color-warning-light', 'isTypeSuccess:bg-color-success-light'],
    /**
     * One of: error, success, warning, info. Defaults to info.
     *
     * @attribute type
     * @type {String}
     * @optional
     * @public
     */
    type: 'info',
    /**
     * A title to display.
     *
     * @attribute title
     * @type {String}
     * @optional
     * @public
     */
    title: '',
    /**
     * An icon to render for the info message.
     *
     * @attribute icon
     * @type {String}
     * @optional
     * @public
     */
    icon: null,
    /**
     * Optional action that will be triggered if the close button is clocked.
     * Not providing this option will not render close button.
     *
     * @attribute onClose
     * @type Function
     * @optional
     * @public
     */
    onClose: null,
    /**
     * If `icon` is specified, this will be set as class on the icon.
     *
     * @attribute iconClass
     * @type {String}
     * @default 'icon--medium'
     * @public
     */
    iconClass: 'icon--medium',
    isTypeInfo: Ember.computed.equal('type', 'info'),
    isTypeError: Ember.computed.equal('type', 'error'),
    isTypeWarning: Ember.computed.equal('type', 'warning'),
    isTypeSuccess: Ember.computed.equal('type', 'success'),
    init() {
      this._super(...arguments);
      (false && !(['info', 'error', 'warning', 'success'].includes(this.type)) && Ember.assert(`"type" for {{info-message}} is "${this.type}" but must be one of info, error, warning, success`, ['info', 'error', 'warning', 'success'].includes(this.type)));
    }
  });
  _exports.default = _default;
});