define("ember-cropster-common/utils/reorderable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ReorderableList = void 0;
  /**
   * This is a reordable list.
   * It has many items, which have a property defining their order (e.g. `order` or `position`).
   * The list can handle updating the position of its items.
   *
   * @namespace EmberCropsterCommon.Utils
   * @class ReorderableList
   * @extends Ember.Object
   * @public
   */
  const ReorderableList = Ember.Object.extend({
    /**
     * The initial items for the list.
     *
     * @attribute items
     * @type {Object[]}
     * @public
     */
    items: null,
    /**
     * The property of the items which is used for the order.
     *
     * @property orderProperty
     * @type {String}
     * @protected 'order'
     * @public
     */
    orderProperty: 'order',
    /**
     * The min. distance of distances between two adjacent items at which to try to fix their order.
     * This is used to ensure that two items don't get too close to each other.
     * Set this to 0 to deactivate the auto fixing.
     *
     * @property minOrderAdaptDistance
     * @type {Number}
     * @default 0.001
     * @protected
     */
    minOrderAdaptDistance: 0.001,
    /**
     * The step to use for orders that are appended to the end of the list.
     *
     * @property orderStep
     * @type {Number}
     * @default 16384
     * @protected
     */
    orderStep: 16384,
    /**
     * The min. order number is used to fix items where the order is 0 (which is not supported).
     *
     * @property minOrder
     * @type {Number}
     * @default 0.00001
     * @protected
     */
    minOrder: 0.00001,
    /**
     * If this is set to true, the list will output debug information on addItem/updateItem.
     *
     * @property debug
     * @type {Boolean}
     * @default false
     * @protected
     */
    debug: false,
    /**
     * Return all items in the correct order.
     *
     * @method getItems
     * @return {Object[]}
     * @public
     */
    getItems() {
      return Ember.get(this, 'items');
    },
    /**
     * Remove an item from the list.
     * This will not update any order numbers.
     *
     * @method removeItem
     * @param {Object} item
     * @return {Object[]} The remaining items
     * @public
     */
    removeItem(item) {
      let items = Ember.get(this, 'items');
      items.removeObject(item);
      return items;
    },
    /**
     * Add an item to the list.
     * If order is null, the item will be added to the bottom of the list.
     * Else, it will be added before the given order.
     *
     * @method addItem
     * @param {Object} item
     * @param {Number|null} order
     * @return {Object[]} The remaining items
     * @public
     */
    addItem(item) {
      let order = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let items = Ember.get(this, 'items');
      let prop = Ember.get(this, 'orderProperty');
      let pos = this._getPositionForOrder(order);
      let newOrder = this._getOrderForPosition(pos);
      items.insertAt(pos, item);
      Ember.set(item, prop, newOrder);
      this._checkOrders();
      if (Ember.get(this, 'debug')) {
        /* eslint-disable no-console */
        console.log(`insert into pos ${pos} with new order ${newOrder}`);
        console.log(items.mapBy('order'));
        /* eslint-enable no-console */
      }

      return items;
    },
    /**
     * Update an item in the list.
     * If order is null, the item will be moved to the bottom of the list.
     * Else, it will be moved before the given order.
     *
     * @method updateItem
     * @param {Object} item
     * @param {Number|null} order
     * @return {Object[]} The remaining items
     * @public
     */
    updateItem(item) {
      let order = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let items = Ember.get(this, 'items');
      let prop = Ember.get(this, 'orderProperty');

      // First remove it from the current position...
      items.removeObject(item);
      let pos = this._getPositionForOrder(order);
      let newOrder = this._getOrderForPosition(pos);
      items.insertAt(pos, item);
      Ember.set(item, prop, newOrder);
      this._checkOrders();
      if (Ember.get(this, 'debug')) {
        /* eslint-disable no-console */
        console.log(`insert into pos ${pos} with new order ${newOrder}`);
        console.log(items.mapBy('order'));
        /* eslint-enable no-console */
      }

      return items;
    },
    /**
     * This is a hook that can be overwritten.
     * This function is called whenever an item is auto-changed by the `_checkOrders` function due to the distance
     * between orders being too small.
     *
     * @method itemWasChanged
     * @protected
     */
    itemWasChanged() {},
    /**
     * Init the order of the list according to the order.
     * This is auto-called on `init()` of the list.
     *
     * @method initOrder
     * @public
     */
    initOrder() {
      let items = Ember.get(this, 'items');
      let prop = Ember.get(this, 'orderProperty');
      items = items.sortBy(prop);
      Ember.set(this, 'items', items);
    },
    /**
     * Check all the orders of the list.
     * This will compare each order to the next order, and see if their distance is large enough
     * (according to the `minOrderAdaptDistance` property). If it isn't it will try to adapt the order.
     *
     * @method _checkOrders
     * @private
     */
    _checkOrders() {
      let items = Ember.get(this, 'items');
      let prop = Ember.get(this, 'orderProperty');
      let orderStep = Ember.get(this, 'orderStep');
      let minOrderAdaptDistance = Ember.get(this, 'minOrderAdaptDistance');
      let minOrder = Ember.get(this, 'minOrder');
      items.forEach((item, i) => {
        if (i === 0) {
          // For the first one, check if the order is 0, and if so, fix it
          let order = Ember.get(item, prop);
          if (!order) {
            let next = items[i + 1];
            let nextOrder = next ? Ember.get(next, prop) : order + orderStep;

            // Set it either to half the next item, or to the minOrder if not otherwise possible
            let newOrder = nextOrder / 2;
            Ember.set(item, prop, newOrder || minOrder);
            this.itemWasChanged(item);
          }
          return;
        }
        let order = Ember.get(item, prop);
        let previousOrder = Ember.get(items[i - 1], prop);
        let diff = Math.abs(order - previousOrder);
        if (diff < minOrderAdaptDistance) {
          let next = items[i + 1];
          let nextOrder = next ? Ember.get(next, prop) : order + orderStep;
          let newOrder = (order + nextOrder) / 2;
          Ember.set(item, prop, newOrder);
          this.itemWasChanged(item);
        }
      });
    },
    /**
     * Get the list position before the given order.
     *
     * @method _getPositionForOrder
     * @param {Number|null} order
     * @return {Number|number} The list position
     * @private
     */
    _getPositionForOrder() {
      let order = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let items = Ember.get(this, 'items');
      let prop = Ember.get(this, 'orderProperty');

      // If no order is given, add it to the end
      let insertBefore = Ember.isNone(order) ? null : items.find(item => {
        return Ember.get(item, prop) >= order;
      });
      return insertBefore ? items.indexOf(insertBefore) : Ember.get(items, 'length');
    },
    /**
     * Get the correct order for the given position in the list.
     * This will take the items before and after the given position into account.
     *
     * @method _getOrderForPosition
     * @param {Number} pos
     * @return {Number} The order property
     * @private
     */
    _getOrderForPosition() {
      let pos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      let items = Ember.get(this, 'items');
      let prop = Ember.get(this, 'orderProperty');
      let orderStep = Ember.get(this, 'orderStep');
      let lastOrder = Ember.get(items, `lastObject.${prop}`) || 0;
      let order = lastOrder + orderStep;
      let previousOrder = items[pos - 1];
      let nextOrder = items[pos];
      previousOrder = previousOrder ? Ember.get(previousOrder, prop) : null;
      nextOrder = nextOrder ? Ember.get(nextOrder, prop) : null;
      if (!Ember.isNone(previousOrder) && !Ember.isNone(nextOrder)) {
        order = (nextOrder + previousOrder) / 2;
      } else if (!Ember.isNone(nextOrder)) {
        order = nextOrder / 2;
      } else if (!Ember.isNone(previousOrder)) {
        order = previousOrder + orderStep;
      }
      return order;
    },
    init() {
      if (!Ember.get(this, 'items')) {
        Ember.set(this, 'items', []);
      }
      this.initOrder();
    }
  });
  _exports.ReorderableList = ReorderableList;
  var _default = ReorderableList;
  _exports.default = _default;
});