define("ember-cropster-common/components/loading-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OPMEuPr+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"loading-spinner \",[28,\"if\",[[24,[\"isCompact\"]],\"loading-spinner--compact\"],null],\" \",[22,\"loadingSpinnerClass\"]]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/loading-spinner/template.hbs"
    }
  });
  _exports.default = _default;
});