define("ember-cropster-common/helpers/in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.inArray = inArray;
  function inArray(value, array) {
    if (Ember.typeOf(array) !== 'array') {
      return false;
    }
    return array.indexOf(value) > -1;
  }

  /**
   * A helper to check if a given value is in a given array.
   *
   * @class InArray
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{in-array 1 (to-array 1 2)}}
   * ```
   */
  var _default = Ember.Helper.extend({
    _array: null,
    compute(_ref) {
      let [value, array] = _ref;
      this.setupRecompute(array);
      return inArray(value, array);
    },
    destroy() {
      if (this.teardown) {
        this.teardown();
      }
      this._super(...arguments);
    },
    setupRecompute(array) {
      if (this.teardown) {
        this.teardown();
      }
      Ember.set(this, '_array', array);
      this.addObserver('_array.[]', this, this.recompute);
      this.teardown = () => {
        this.removeObserver('_array.[]', this, this.recompute);
      };
    }
  });
  _exports.default = _default;
});