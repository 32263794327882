define("ember-cropster-common/helpers/is-even", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEven = isEven;
  function isEven(params) {
    if (!params) {
      return false;
    }
    let [number] = params;
    if (Ember.typeOf(number) !== 'number') {
      return false;
    }
    return number % 2 === 0;
  }
  var _default = Ember.Helper.helper(isEven);
  _exports.default = _default;
});