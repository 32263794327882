define("ember-cropster-common/helpers/svg-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkIconExists = checkIconExists;
  _exports.default = void 0;
  _exports.svgIcon = svgIcon;
  _exports.svgIconHelper = svgIconHelper;
  _exports.svgIconIE = svgIconIE;
  const viewBoxes = {
    'download-pdf': '0 0 39 15',
    'download-pdf-summary': '0 0 58 15',
    'download-xls': '0 0 39 15',
    see: '0 0 30 19',
    blind: '0 0 30 24',
    'double-blind': '0 0 30 24',
    delete: '0 0 20 26',
    'drag-n-drop': '0 0 18 30',
    'arrow-down': '0 0 30 20',
    'arrow-thin-down': '0 0 20 20',
    merge: '0 0 20 20',
    'cupping-labels': '0 0 25 20',
    'cupping-labels-color': '0 0 25 20',
    'cupping-sheets': '0 0 25 20',
    'cupping-sheets-color': '0 0 25 20',
    lab: '0 0 20 27',
    'lab-color': '0 0 20 27',
    crown: '0 0 30 20',
    profile: '0 0 30 20',
    start: '0 0 20 27',
    list: '0 0 30 20',
    more: '0 0 50 20',
    unlink: '0 0 20 24',
    'coffee-bean-roasting': '0 0 20 30',
    user: '0 0 17 20',
    comment: '0 0 20 18',
    login: '0 0 20 20',
    logout: '0 0 20 20',
    upload: '0 0 20 20',
    file: '0 0 18 20',
    location: '0 0 16 20',
    'scale-weights': '0 0 28 20',
    'cocoa-bean': '0 0 20 36',
    reorder: '0 0 26 33'
  };
  const ua = window.navigator.userAgent;
  const isIE = ua.indexOf('MSIE ') > -1 || !!ua.match(/Trident.*rv:11\./);
  function svgIcon(file, _ref) {
    let {
      classes = '',
      viewBox = '0 0 20 20'
    } = _ref;
    return Ember.String.htmlSafe(`<svg role="img" class="svg icon ${classes}" viewBox="${viewBox}"><use xlink:href="#${file}"></use></svg>`);
  }
  function svgIconIE(file, _ref2) {
    let {
      classes = '',
      viewBox = '0 0 20 20'
    } = _ref2;
    let [,, width, height] = viewBox.split(' ');
    return Ember.String.htmlSafe(`<div class="svg icon ${classes} icon--with-canvas" role="img">
  <canvas class="icon__canvas" width="${width}" height="${height}"></canvas>
  <svg role="img" class="icon icon--absolute ${classes}" viewBox="${viewBox}"><use xlink:href="#${file}"></use></svg>
</div>`);
  }
  function checkIconExists(file) {
    return !!document.querySelector(`svg#${file},symbol#${file}`);
  }
  function svgIconHelper(_ref3) {
    let [file] = _ref3;
    let hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let classes = Ember.get(hash, 'class') || '';
    let viewBox = Ember.get(hash, 'viewBox');
    if (!viewBox && file) {
      viewBox = Ember.get(viewBoxes, file) || '0 0 20 20';
    }
    (false && !(checkIconExists(file)) && Ember.assert(`icon "${file}" exists in svg-sprite`, checkIconExists(file)));
    return isIE ? svgIconIE(file, {
      classes,
      viewBox
    }) : svgIcon(file, {
      classes,
      viewBox
    });
  }

  /**
   * A helper to render an inline svg.
   *
   * @class SvgIcon
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{svg-icon 'check'}}
   * ```
   */
  var _default = Ember.Helper.helper(svgIconHelper);
  _exports.default = _default;
});