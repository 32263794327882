define("ember-cropster-common/components/c-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bL3u8N9m",
    "block": "{\"symbols\":[\"data\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"header\",\"content\"],[[28,\"component\",[\"c-box/-blank\"],[[\"data-test-cbox-title\",\"class\"],[true,[28,\"class-names\",[\"padding-all-2\",\"border-all-bottom\",\"border-all-color-grey-3\",\"bold\",[28,\"if\",[[24,[\"isTypeBasic\"]],\"bg-color-grey-4\"],null],[28,\"if\",[[24,[\"isTypeHighlight\"]],\"bg-color-grey-2\"],null],[28,\"if\",[[24,[\"isTypePrimary\"]],\"color-white\"],null],[28,\"if\",[[24,[\"isTypePrimary\"]],\"bg-color-primary\"],null],[28,\"if\",[[24,[\"isTypeWhite\"]],\"bg-color-white\"],null]],null]]]],[28,\"component\",[\"c-box/-blank\"],[[\"data-test-cbox-content\",\"class\"],[true,\"padding-all-2\"]]]]]]],null,{\"statements\":[[0,\"\\n  \"],[14,2,[[23,1,[]]]],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-box/template.hbs"
    }
  });
  _exports.default = _default;
});