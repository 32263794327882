define("ember-cropster-common/helpers/to-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toInt = toInt;
  function toInt(_ref) {
    let [val] = _ref;
    if (!val && val !== 0) {
      return null;
    }
    let intVal = parseInt(val, 10);
    return intVal || intVal === 0 ? intVal : null;
  }

  /**
   * A helper to convert a value to an integer.
   *
   * @class ToInt
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{to-int 1.23}}
   * ```
   */
  var _default = Ember.Helper.helper(toInt);
  _exports.default = _default;
});