define("ember-cropster-common/components/modal-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jvctEFeY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[24,[\"wormholeElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"modal__overlay \",[28,\"if\",[[24,[\"isOpen\"]],\"modal__overlay--open\"],null]]]],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"modal__wrapper \",[28,\"if\",[[24,[\"isOpen\"]],\"modal__wrapper--open\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"outsideClick\"],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"data-test-modal\",\"\"],[10,\"data-test-id\",\"modalWindow\"],[11,\"class\",[29,[\"modal \",[22,\"modalClasses\"]]]],[10,\"tabindex\",\"-1\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"enableAutoClose\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"data-test-id\",\"nativeClose\"],[12,\"class\",\"modal__close button button--icon button--plain button--icon--plain\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n          \"],[1,[28,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--light-grey\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h2\",true],[10,\"data-test-id\",\"title\"],[10,\"class\",\"modal__title\"],[8],[0,\"\\n          \"],[1,[22,\"title\"],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"div\",true],[10,\"data-test-id\",\"yielded\"],[10,\"class\",\"modal__content\"],[8],[0,\"\\n\\n        \"],[14,1,[[28,\"action\",[[23,0,[]],\"close\"],null]]],[0,\"\\n\\n      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/modal-overlay/template.hbs"
    }
  });
  _exports.default = _default;
});