define("ember-cropster-common/utils/range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = range;
  /*
   * Creates an array of positive numbers progressing
   * from start up to (but not including) end.
   * A step of 1 is used as a default.
   * If end is not specified, it's set to start with start then set to 0.
   */
  function range(start, end) {
    let step = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    (false && !(Ember.typeOf(start) === 'number') && Ember.assert('start must be number', Ember.typeOf(start) === 'number'));
    (false && !(start >= 0) && Ember.assert('start must be bigger than 0', start >= 0));
    (false && !(Ember.typeOf(step) === 'number') && Ember.assert('step must be number', Ember.typeOf(step) === 'number'));
    (false && !(step > 0) && Ember.assert('step must be bigger than 0', step > 0));
    if (end === undefined) {
      end = start;
      start = 0;
    }
    (false && !(Ember.typeOf(end) === 'number') && Ember.assert('end must be a number', Ember.typeOf(end) === 'number'));
    (false && !(end > start) && Ember.assert('end must be bigger than start', end > start));
    let result = [start];
    let currentValue = start + step;
    while (currentValue < end) {
      result.push(currentValue);
      currentValue = currentValue + step;
    }
    return result;
  }
});