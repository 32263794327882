define("ember-cropster-common/components/toast-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xgsVH/w4",
    "block": "{\"symbols\":[\"toastItem\"],\"statements\":[[4,\"if\",[[24,[\"wormholeElement\"]]],null,{\"statements\":[[4,\"in-element\",[[24,[\"wormholeElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"toast-container\"],[10,\"data-toast-container\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"toastItems\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"toast-container/message\",null,[[\"toastItem\",\"onRemove\",\"data-test-toast\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"removeToast\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/toast-container/template.hbs"
    }
  });
  _exports.default = _default;
});