define("ember-cropster-common/helpers/class-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classNames = classNames;
  _exports.default = void 0;
  function classNames(params) {
    if (Ember.isEmpty(params)) {
      return null;
    }
    return params.filter(i => !Ember.isEmpty(i)).join(' ');
  }
  var _default = Ember.Helper.helper(classNames);
  _exports.default = _default;
});