define("ember-cropster-common/helpers/join-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.joinArray = joinArray;
  function joinArray(_ref) {
    let [arr = [], separator = ', '] = _ref;
    if (Ember.typeOf(arr) !== 'array') {
      return '';
    }
    return Ember.A(arr).compact().join(separator);
  }

  /**
   * A helper to join an array with an optional separator (defaults to `, `).
   *
   * @class JoinArray
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{join-array (to-array 1 2 3 4) ' - '}}
   * ```
   */
  var _default = Ember.Helper.helper(joinArray);
  _exports.default = _default;
});