define("ember-cropster-common/components/modal-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "65FiXp9v",
    "block": "{\"symbols\":[\"close\"],\"statements\":[[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[4,\"modal-overlay\",null,[[\"title\",\"modalClasses\",\"enableAutoClose\",\"close\"],[[24,[\"title\"]],[24,[\"modalClassNames\"]],false,[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"mode\"]],\"confirm-warn\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"modal--dialog__icon color-warning\"],[8],[0,\"\\n        \"],[1,[28,\"svg-icon\",[\"warning\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal--dialog__content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"messageHasHtml\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"message\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"format-nl2br\",[[24,[\"message\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal--dialog__buttons\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"cancelButtonText\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",\"button button--secondary\"],[12,\"data-test-modal-button-cancel\",\"\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[23,1,[]],\"cancel\"],null]]],[8],[0,\"\\n          \"],[1,[22,\"cancelButtonText\"],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"button button--primary\"],[12,\"autofocus\",\"true\"],[12,\"data-modal-primary-button\",\"\"],[12,\"data-test-modal-button-confirm\",\"\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[23,1,[]],\"confirm\"],null]]],[8],[0,\"\\n        \"],[1,[22,\"confirmButtonText\"],false],[0,\"\\n      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/modal-dialog/template.hbs"
    }
  });
  _exports.default = _default;
});