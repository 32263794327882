define("ember-cropster-common/components/c-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This is a one-way input.
   *
   * By default, it acts as a text input, but you can also use it as checkbox or radio button.
   * Basically, this is a wrapper component for `<input>`.
   *
   * Note that the values DO NOT auto update, there is no two-way binding!
   *
   * @namespace Component
   * @class CInput
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{c-input value=myInitialValue onUpdate=(action "myAction")}}
   * ```
   */
  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['accept', 'autocomplete', 'autosave', 'checked', 'dir', 'disabled', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'max', 'min', 'multiple', 'name', 'pattern', 'placeholder', 'size', '_step:step', 'type', 'title', 'value', 'width', 'required'],
    classNameBindings: ['isTextLike:input--text', 'isRadio:input--radio', 'isCheckbox:input--checkbox', 'addErrorClass:input--error'],
    /**
     * The type of the input. Defaults to text, can also be any other valid HTML5 input type.
     * If type=radio or type=checkbox, the behaviour of the input will be slightly different.
     * Can also be e.g. password, email, number or other special input types.
     *
     * @attribute type
     * @type {String}
     * @default 'text'
     * @public
     */
    type: 'text',
    /**
     * The value of the input. Note that this is now two-way data bound!
     * For type=checkbox, checked will be used instead.
     *
     * @attribute value
     * @type {String}
     * @public
     */
    value: undefined,
    /**
     * If the input should be disabled. If it is, its value cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @optional
     * @public
     */
    disabled: false,
    /**
     * An optional placeholder for this input. Use a translated string for this.
     * Has no effect for type=checkbox or type=radio.
     *
     * @attribute placeholder
     * @type {String}
     * @optional
     * @public
     */
    placeholder: null,
    /**
     * The possible steps if type=number.
     *
     * @attribute step
     * @type {Number}
     * @optional
     * @public
     */
    step: null,
    _step: null,
    /**
     * If filling/selection of this input is required.
     * Note that this will only do HTML5 validation, you will still need to validate inputs manually.
     *
     * @attribute required
     * @type {Boolean}
     * @optional
     * @public
     */
    required: false,
    /**
     * If the input should be checked by default.
     * Can be used if type=checkbox or type=radio. For type=checkbox, the checked-value will also be used
     * as the value sent to the action.
     *
     * @attribute checked
     * @type {Boolean}
     * @optional
     * @public
     */
    checked: undefined,
    /**
     * If this is true, empty strings are converted to undefined values.
     *
     * @attribute emptyIsUndefined
     * @type {Boolean}
     * @default false
     * @public
     */
    emptyIsUndefined: false,
    /**
     * If set to true, auto-select the whole input's content on click.
     *
     * @attribute selectOnClick
     * @type {Boolean}
     * @default false
     * @public
     */
    selectOnClick: false,
    /**
     * If set to true, auto-select & copy the whole input's content on click.
     *
     * @attribute copyOnClick
     * @type {Boolean}
     * @default false
     * @public
     */
    copyOnClick: false,
    /**
     * If the field has an error.
     * In this case, and if the field is not null/undefined, it will add an input--error class.
     *
     * @attribute hasError
     * @type {Boolean}
     * @default false
     * @public
     */
    hasError: false,
    /**
     * The action to call when the value changes.
     *
     * @event onUpdate
     * @param {Mixed} value
     * @public
     */
    onUpdate: null,
    update: null,
    // Old alias - will be deprecated
    _update: Ember.computed('onUpdate', 'update', function () {
      return this.onUpdate || this.update;
    }),
    /**
     * If copyOnClick = true, this action is called after copying.
     * This will receive a parameter, which is true if copying was successfull, else false.
     * For example, a browser might not support copy pasting - then the param would be false.
     *
     * @event copyAction
     * @param {Boolean} wasSuccessfull
     * @public
     */
    copyAction: null,
    /**
     * If set to true, auto-focus this input after inserting it in the DOM.
     *
     * @attribute autoFocus
     * @type {Boolean}
     * @default false
     * @public
     */
    autoFocus: false,
    /**
     * The language to use.
     * This is only used for number input fields.
     *
     * @property lang
     * @type {String}
     * @readOnly
     * @protected
     */
    lang: Ember.computed(function () {
      if (Ember.get(this, 'isNumberInput')) {
        return window.navigator.language;
      }
      return undefined;
    }),
    /**
     * If the error class should be added or not.
     * It will be added if: hasError is true & the value is not null/undefined.
     * Note that the CSS class added will only have an effect if the element has no focus.
     *
     * @property addErrorClass
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    addErrorClass: Ember.computed('hasError', 'value', function () {
      if (!Ember.get(this, 'hasError')) {
        return false;
      }

      // Don't mark as error if there is no value yet
      if (Ember.isNone(Ember.get(this, 'value'))) {
        return false;
      }
      return true;
    }),
    KEY_EVENTS: Ember.computed(function () {
      return {
        '13': 'onEnter',
        '27': 'onEscape',
        '38': 'onArrowUp',
        '40': 'onArrowDown'
      };
    }),
    /**
     * The sanitized, internal value.
     *
     * @property _sanitizedValue
     * @type {Mixed}
     * @private
     */
    _sanitizedValue: undefined,
    /**
     * If the input is a radio box.
     *
     * @property isRadio
     * @type {Boolean}
     * @protected
     * @readOnly
     */
    isRadio: Ember.computed.equal('type', 'radio'),
    /**
     * If the input is a checkbox.
     *
     * @property isCheckbox
     * @type {Boolean}
     * @protected
     * @readOnly
     */
    isCheckbox: Ember.computed.equal('type', 'checkbox'),
    /**
     * If the input is a number input.
     *
     * @property isNumberInput
     * @type {Boolean}
     * @protected
     * @readOnly
     */
    isNumberInput: Ember.computed.equal('type', 'number'),
    /**
     * If the input is some kind of text-like input.
     * This also applies to email, number, password, ...
     *
     * @property isTextLike
     * @type {Boolean}
     * @protected
     * @readOnly
     */
    isTextLike: Ember.computed('isRadio', 'isCheckbox', function () {
      return !this.isRadio && !this.isCheckbox;
    }),
    /*
     * Defines what keyboard is shown on mobile devices.
     */
    inputmode: Ember.computed('isNumberInput', function () {
      return this.isNumberInput ? 'decimal' : undefined;
    }),
    /**
     * On input event, handle the change event.
     *
     * @event input
     * @protected
     */
    input(event) {
      // Number inputs are only handled in change() & keyUp()
      // Checkboxes/Radio buttons are handled via change
      if (Ember.get(this, 'isNumberInput') || Ember.get(this, 'isCheckbox') || Ember.get(this, 'isRadio')) {
        return;
      }
      this._handleChangeEvent(event);
    },
    keyDown() {
      // We save this in here, as it might already be deleted later on
      // This is used by the number input
      Ember.set(this, '_selectedText', window.getSelection().toString());
    },
    /**
     * On key up action, handle the change event.
     *
     * @event keyUp
     * @protected
     */
    keyUp(event) {
      this._interpretKeyEvents(event);
      if (Ember.get(this, 'isNumberInput') && this._checkNumberKeyUpEvent(event)) {
        this._handleChangeEvent(event);
      }
    },
    /**
     * This method is responsible for evaluating if a key press for a number input should result in the
     * change action being sent.
     *
     * This is necessary, as different browsers handle number inputs quite differently.
     * In here, we handle two basic cases: Adding a number, and removing a number via backspace.
     * For these cases, the change action should be called right away.
     * For other cases, it will NOT be called right away - e.g. if selecting text and removing it all.
     * In these cases, the change action will only be called if the input is blurred or if another number is typed.
     *
     * @method _checkNumberKeyUpEvent
     * @param {Object} event
     * @return {Boolean}
     * @private
     */
    _checkNumberKeyUpEvent(event) {
      let domValue = this._readAppropriateAttr();
      let {
        key
      } = event;
      let {
        element
      } = this;

      // We cache the previous dom value, in order to handle Backspace events
      let previousValue = Ember.get(this, '_domValue') || '';
      Ember.set(this, '_domValue', domValue);

      // If the input is not valid, never trigger the action
      if (!element.validity.valid) {
        return false;
      }

      // If something is selected, everything would become really complex,
      // so we leave it to the change event in this case
      if (Ember.get(this, '_selectedText')) {
        return false;
      }

      // If the character that was just typed & has been added to the domValue
      let charHasBeenAdded = domValue.split('').get('lastObject') === key;
      if (key === 'Backspace') {
        let hasDot = previousValue.includes('.');
        let hasComma = previousValue.includes(',');
        let previousValueLength = previousValue.length;

        // On backspace, we want to trigger the action only if it would not leave a hanging comma
        // E.g. if the previous value was: 12.5, backspace would leave to 12., which we want to ignore
        // But if the previous value was 12.55, backspace would leave to 12.5, which is valid
        let lastCharacterIsComma = false;
        if (hasDot && previousValue.indexOf('.') === previousValueLength - 2) {
          lastCharacterIsComma = true;
        }
        if (hasComma && previousValue.indexOf(',') === previousValueLength - 2) {
          lastCharacterIsComma = true;
        }
        if (!lastCharacterIsComma) {
          return true;
        }
      } else if (charHasBeenAdded) {
        return true;
      }
      return false;
    },
    /**
     * On change event, handle the change event.
     *
     * @event change
     * @protected
     */
    change(event) {
      // For number inputs, do not send change event when bad input, e.g. no number
      // Otherwise, it would send 'null' e.g. on FF if the user enters "5.5" instead of "5,5".
      // This clears out the value and leads to unexpected side effects :(
      if (this.isNumberInput && this.element && this.element.validity.badInput) {
        return;
      }
      this._handleChangeEvent(event);
    },
    /**
     * On blur event, handle the blur event.
     *
     * @event blur
     * @protected
     */
    focusOut(event) {
      this._handleBlurEvent(event);
    },
    click() {
      if (Ember.get(this, 'selectOnClick') || Ember.get(this, 'copyOnClick')) {
        this.element.select();
        if (Ember.get(this, 'copyOnClick')) {
          let successful = false;
          try {
            successful = document.execCommand('copy');
          } catch (e) {
            successful = false;
          }
          let action = Ember.get(this, 'copyAction');
          if (action) {
            action(successful);
          }
        }
      }
      return true;
    },
    /**
     * Handle specific key events.
     * The user can also add special actions for these key events.
     *
     * @method _interpretKeyEvents
     * @param event
     * @private
     */
    _interpretKeyEvents(event) {
      let methodName = Ember.get(this, 'KEY_EVENTS')[event.keyCode];
      if (methodName) {
        this._sanitizedValue = null;
        this._processNewValue.call(this, methodName, this._readAppropriateAttr());
      }
    },
    /**
     * Process the new value and send a changed action.
     *
     * @method _handleChangeEvent
     * @private
     */
    _handleChangeEvent() {
      this._processNewValue.call(this, '_update', this._readAppropriateAttr());
    },
    /**
     * Process the new value and send a changed action.
     *
     * @method _handleBlurEvent
     * @private
     */
    _handleBlurEvent() {
      let value = this._sanitizeInput(this._readAppropriateAttr());
      let action = Ember.get(this, 'attrs.blurAction');
      if (action) {
        action(value);
      }
    },
    /**
     * Get the appropriate value from the input.
     * This is normally the value, except for type=checkbox, where it is the checked status.
     *
     * @method _readAppropriateAttr
     * @return {String|Boolean}
     * @private
     */
    _readAppropriateAttr() {
      if (Ember.get(this, 'isCheckbox')) {
        return this.readDOMAttr('checked');
      }
      if (Ember.get(this, 'isRadio')) {
        return Ember.get(this, 'value');
      }

      // If it is a number input, replace , with .
      if (Ember.get(this, 'isNumberInput')) {
        let domValue = this.readDOMAttr('value');
        if (domValue && Ember.typeOf(domValue) === 'string') {
          domValue = domValue.replace(',', '.');
        }
        return domValue;
      }

      // If it is a text element, use readDOMAttr() to prevent jumping of the cursor
      return this.readDOMAttr('value');
    },
    /**
     * Process a new value and send a change action if it has changed.
     *
     * @method _processNewValue
     * @param {String} methodName The action name to send
     * @param {String|Boolean} rawValue The new value
     * @private
     */
    _processNewValue(methodName, rawValue) {
      let value = this._sanitizeInput(rawValue);
      let action = Ember.get(this, methodName);
      if (this._sanitizedValue !== value || Ember.get(this, 'isRadio') || Ember.get(this, 'isCheckbox')) {
        this._sanitizedValue = value;
        if (action) {
          action(value);
        }
      }
    },
    /**
     * This function can at some point be used to sanitize the input before it is passed to the action.
     *
     * @method _sanitizeInput
     * @param {String} input
     * @return {String}
     * @private
     */
    _sanitizeInput(input) {
      if (Ember.get(this, 'emptyIsUndefined') && input === '') {
        input = undefined;
      }

      // For type=number, convert to number
      if (Ember.get(this, 'isNumberInput')) {
        return !Ember.isBlank(input) ? input * 1 : null;
      }
      return input;
    },
    /**
     * Update the sanitized value.
     *
     * @method didReceiveAttrs
     * @override
     * @protected
     */
    didReceiveAttrs() {
      this._super(...arguments);
      if (Ember.get(this, 'isRadio')) {
        return;
      }
      let value = Ember.get(this, 'value') || Ember.get(this, 'checked');
      this._sanitizedValue = value;

      // If step is not set for number inputs, it uses the browser default (usually 1)
      // We want to have "any" as default (if not specified)
      if (this.isNumberInput && Ember.isNone(this.step)) {
        Ember.set(this, '_step', 'any');
      } else {
        Ember.set(this, '_step', this.step);
      }
    },
    didInsertElement() {
      if (Ember.get(this, 'autoFocus')) {
        this.element.focus();
      }
    }
  });
  _exports.default = _default;
});