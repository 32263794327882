define("ember-page-title/services/page-title-list", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @class page-title-list
    @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    init() {
      this._super();
      Ember.set(this, 'tokens', Ember.A());
      Ember.set(this, 'length', 0);
      this._removeExistingTitleTag();
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      if (config.pageTitle) {
        ['separator', 'prepend', 'replace'].forEach(key => {
          if (Ember.isPresent(config.pageTitle[key])) {
            Ember.set(this, `default${Ember.String.capitalize(key)}`, config.pageTitle[key]);
          }
        });
      }
    },
    /**
      The default separator to use between tokens.
       @property defaultSeparator
      @default ' | '
     */
    defaultSeparator: ' | ',
    /**
      The default prepend value to use.
       @property defaultPrepend
      @default true
     */
    defaultPrepend: true,
    /**
      The default replace value to use.
       @property defaultReplace
      @default null
     */
    defaultReplace: null,
    tokens: null,
    applyTokenDefaults(token) {
      let defaultSeparator = Ember.get(this, "defaultSeparator");
      let defaultPrepend = Ember.get(this, "defaultPrepend");
      let defaultReplace = Ember.get(this, "defaultReplace");
      if (token.separator == null) {
        token.separator = defaultSeparator;
      }
      if (token.prepend == null && defaultPrepend != null) {
        token.prepend = defaultPrepend;
      }
      if (token.replace == null && defaultReplace != null) {
        token.replace = defaultReplace;
      }
    },
    inheritFromPrevious(token) {
      let previous = token.previous;
      if (previous) {
        if (token.separator == null) {
          token.separator = previous.separator;
        }
        if (token.prepend == null) {
          token.prepend = previous.prepend;
        }
      }
    },
    push(token) {
      let tokenForId = this.tokens.findBy('id', token.id);
      if (tokenForId) {
        let index = this.tokens.indexOf(tokenForId);
        let tokens = (0, _emberCopy.copy)(this.tokens);
        let previous = tokenForId.previous;
        token.previous = previous;
        token.next = tokenForId.next;
        this.inheritFromPrevious(token);
        this.applyTokenDefaults(token);
        tokens.splice(index, 1, token);
        Ember.set(this, 'tokens', Ember.A(tokens));
        return;
      }
      let previous = this.tokens.slice(-1)[0];
      if (previous) {
        token.previous = previous;
        previous.next = token;
        this.inheritFromPrevious(token);
      }
      this.applyTokenDefaults(token);
      let tokens = (0, _emberCopy.copy)(this.tokens);
      tokens.push(token);
      Ember.set(this, 'tokens', Ember.A(tokens));
      Ember.set(this, 'length', Ember.get(this, 'length') + 1);
    },
    remove(id) {
      let token = this.tokens.findBy('id', id);
      let {
        next,
        previous
      } = token;
      if (next) {
        next.previous = previous;
      }
      if (previous) {
        previous.next = next;
      }
      token.previous = token.next = null;
      let tokens = Ember.A((0, _emberCopy.copy)(this.tokens));
      tokens.removeObject(token);
      Ember.set(this, 'tokens', Ember.A(tokens));
      Ember.set(this, 'length', Ember.get(this, 'length') - 1);
    },
    visibleTokens: Ember.computed('tokens', {
      get() {
        let tokens = Ember.get(this, 'tokens');
        let i = tokens ? tokens.length : 0;
        let visible = [];
        while (i--) {
          let token = tokens[i];
          if (token.replace) {
            visible.unshift(token);
            break;
          } else {
            visible.unshift(token);
          }
        }
        return visible;
      }
    }),
    sortedTokens: Ember.computed('visibleTokens', {
      get() {
        let visible = Ember.get(this, 'visibleTokens');
        let appending = true;
        let group = [];
        let groups = Ember.A([group]);
        let frontGroups = [];
        visible.forEach(token => {
          if (token.front) {
            frontGroups.unshift(token);
          } else if (token.prepend) {
            if (appending) {
              appending = false;
              group = [];
              groups.push(group);
            }
            let lastToken = group[0];
            if (lastToken) {
              token = (0, _emberCopy.copy)(token);
              token.separator = lastToken.separator;
            }
            group.unshift(token);
          } else {
            if (!appending) {
              appending = true;
              group = [];
              groups.push(group);
            }
            group.push(token);
          }
        });
        return frontGroups.concat(groups.reduce((E, group) => E.concat(group), []));
      }
    }),
    toString() {
      let tokens = Ember.get(this, 'sortedTokens');
      let title = [];
      for (let i = 0, len = tokens.length; i < len; i++) {
        let token = tokens[i];
        if (token.title) {
          title.push(token.title);
          if (i + 1 < len) {
            title.push(token.separator);
          }
        }
      }
      return title.join('');
    },
    /**
     * Remove any existing title tags from the head.
     * @private
     */
    _removeExistingTitleTag() {
      if (this._hasFastboot()) {
        return;
      }
      let titles = document.getElementsByTagName('title');
      for (let i = 0; i < titles.length; i++) {
        let title = titles[i];
        title.parentNode.removeChild(title);
      }
    },
    _hasFastboot() {
      return !!Ember.getOwner(this).lookup('service:fastboot');
    }
  });
  _exports.default = _default;
});