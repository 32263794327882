define("ember-cropster-common/helpers/bread-crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    breadCrumbs: Ember.inject.service(),
    _breadCrumb: null,
    init() {
      this._super();
      this._breadCrumb = {};

      // This needs to be wrapped in this run loop,
      // as otherwise it will not pick up changes correctly
      Ember.run.scheduleOnce('afterRender', () => {
        this.breadCrumbs.add(this._breadCrumb);
      });
    },
    compute(_ref) {
      let [title, model] = _ref;
      let hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let routeName = this._getRouteName(hash);
      (false && !(routeName) && Ember.assert('the {{bread-crumb}} helper needs a routeName - it can only automatically look it up in a route template.', routeName));
      let {
        _breadCrumb
      } = this;

      // This needs to be wrapped in this run loop,
      // as otherwise it will not pick up changes correctly
      Ember.run.scheduleOnce('afterRender', () => {
        Ember.set(_breadCrumb, 'title', title);
        Ember.set(_breadCrumb, 'route', routeName);
        Ember.set(_breadCrumb, 'model', model);
      });
    },
    _getRouteName(_ref2) {
      let {
        routeName,
        context
      } = _ref2;
      if (routeName) {
        return routeName;
      }

      // Context is usually the controller
      // This is set by an AST plugin (see libs/ast-plugins/add-context-to-bread-crumbs)
      // All Ember objects have a _debugContainerKey property, which is the DI lookup path, like controller:roast/overview/index
      // This is an intimate API, but it has been around for a long time, and is used by other big addons as well,
      // like ember-component-css or ember-functional-css
      // If this at one point stops working, the tests will immediately fail due to the assertion in `compute()`
      let contextKey = context && context._debugContainerKey || '';

      // If the context is not a controller, we return an empty string
      if (contextKey.indexOf('controller:') !== 0) {
        return '';
      }

      // Else, we use the controller name as route
      let controllerName = contextKey.replace('controller:', '');
      return controllerName.replace(/\//g, '.');
    },
    destroy() {
      // This needs to be wrapped in this run loop,
      // as otherwise it will not pick up changes correctly
      Ember.run.scheduleOnce('afterRender', () => this.breadCrumbs.remove(this._breadCrumb));
    }
  });
  _exports.default = _default;
});