define("ember-cropster-common/components/bread-crumbs/component", ["exports", "ember-cropster-common/components/bread-crumbs/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Display a list of bread crumbs.
   *
   * @namespace Component
   * @class BreadCrumbs
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{bread-crumbs rootPage='index'}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'ol',
    classNames: ['breadcrumbs'],
    breadCrumbs: Ember.inject.service(),
    /**
     * The root page's routeName. Defaults to 'dashboard'.
     *
     * @attribute rootPage
     * @type {String}
     * @default 'dashboard'
     * @public
     */
    rootPage: 'dashboard',
    /**
     * The items of the bread crumbs.
     *
     * @property items
     * @type {Array}
     * @readOnly
     * @protected
     */
    items: Ember.computed.reads('breadCrumbs.hierarchy')
  });
  _exports.default = _default;
});